// Generated by ReScript, PLEASE EDIT WITH CARE

import * as List from "../../../../../../shared/node_modules/rescript/lib/es6/list.js";
import * as $$Array from "../../../../../../shared/node_modules/rescript/lib/es6/array.js";
import * as Js_array from "../../../../../../shared/node_modules/rescript/lib/es6/js_array.js";
import * as ListUtils from "./ListUtils.bs.js";
import * as Caml_array from "../../../../../../shared/node_modules/rescript/lib/es6/caml_array.js";
import * as Caml_option from "../../../../../../shared/node_modules/rescript/lib/es6/caml_option.js";
import * as $$Notification from "../Notification.bs.js";
import * as Caml_exceptions from "../../../../../../shared/node_modules/rescript/lib/es6/caml_exceptions.js";
import * as Caml_splice_call from "../../../../../../shared/node_modules/rescript/lib/es6/caml_splice_call.js";
import * as Caml_js_exceptions from "../../../../../../shared/node_modules/rescript/lib/es6/caml_js_exceptions.js";

var UnsafeFindFailed = /* @__PURE__ */Caml_exceptions.create("ArrayUtils.UnsafeFindFailed");

function copyAndSort(f, t) {
  var cp = t.slice();
  return Js_array.sortInPlaceWith(f, cp);
}

function copyAndPush(e, t) {
  var copy = t.slice();
  Js_array.push(e, copy);
  return copy;
}

function isEmpty(a) {
  return a.length === 0;
}

function isNotEmpty(a) {
  return a.length !== 0;
}

function unsafeFind(p, message, l) {
  var e = Js_array.find(p, l);
  if (e !== undefined) {
    return Caml_option.valFromOption(e);
  }
  Rollbar.error(message);
  $$Notification.error("An unexpected error occurred", "Our team has been notified about this error. Please try reloading this page.");
  throw {
        RE_EXN_ID: UnsafeFindFailed,
        _1: message,
        Error: new Error()
      };
}

function replaceWithIndex(i, t, l) {
  return Js_array.mapi((function (a, index) {
                if (index === i) {
                  return t;
                } else {
                  return a;
                }
              }), l);
}

function flatten(t) {
  return $$Array.of_list(List.flatten($$Array.to_list(t)));
}

function flattenV2(a) {
  return Caml_splice_call.spliceObjApply([], "concat", [a]);
}

function distinct(t) {
  return $$Array.of_list(ListUtils.distinct($$Array.to_list(t)));
}

function sort_uniq(f, t) {
  return $$Array.of_list(List.sort_uniq(f, $$Array.to_list(t)));
}

function getOpt(a, i) {
  try {
    return Caml_option.some(Caml_array.get(a, i));
  }
  catch (raw_exn){
    var exn = Caml_js_exceptions.internalToOCamlException(raw_exn);
    if (exn.RE_EXN_ID === "Not_found") {
      return ;
    }
    if (exn.RE_EXN_ID === "Invalid_argument") {
      return ;
    }
    throw exn;
  }
}

function swapUp(i, t) {
  if (i <= 0 || i >= t.length) {
    Rollbar.warning("Index to swap out of bounds in array!");
    return t;
  }
  var copy = t.slice();
  Caml_array.set(copy, i, Caml_array.get(t, i - 1 | 0));
  Caml_array.set(copy, i - 1 | 0, Caml_array.get(t, i));
  return copy;
}

function swapDown(i, t) {
  return swapUp(i + 1 | 0, t);
}

function last(t) {
  return t[t.length - 1 | 0];
}

export {
  UnsafeFindFailed ,
  copyAndSort ,
  copyAndPush ,
  isEmpty ,
  isNotEmpty ,
  unsafeFind ,
  replaceWithIndex ,
  flatten ,
  flattenV2 ,
  distinct ,
  sort_uniq ,
  getOpt ,
  swapUp ,
  swapDown ,
  last ,
}
/* Notification Not a pure module */
